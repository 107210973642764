


// new code 

@media (max-width: 767px) {
    .padding_80 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .dis_show {
        display: block;
    }

    .dis_hide {
        display: none;
    }

    .content_none {
        display: none;
    }

    .header_menu {
        .top_nav_bar {
            .menu_icon_btn { 
                font-size: 4vw; 

                svg {
                    height: 4.3vw;
                }
            }
            .logo_col {
                img { 
                    height: 4.5vh;
                }
            }
        } 

        .collapse_menu_list { 
            font-size: 4vw; 
            background: #0071ce;
            border-radius: 15px;

            .navbar-nav {
                padding: 15px 0;
            }
        } 
    }  

    .cover_section {
        background-image: url('../../public/images/mobile/1.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;

        background-size: contain;
        background-position: top center;


        .overlap {
            .bg_shape { 
                bottom: -20%;
                img { 
                    height: 100vh;
                }
            }

            .cover_content {
                .cover_title {
                    .h1_middle {
                        h1 { 
                            font-size: 9vw;  
                        }
                        bottom: unset; 
                        top: 43vh;
                    }
                }
            }

            .discover_div {
                .discover_a {
                    padding: 1.3vw 3.5vw; 
                    font-size: 4vw; 
                    border: 2px solid #000;  
                    color: #000; 
                }
            }
        }

        .scroll_down {
            svg path{
                stroke:#000
            }
        }
    }

    .transition_section {
        .cover_section {
            background-image: url('../../public/images/mobile/1.png');
            background-size: contain;
            background-position: center top;

            .bg_shape { 
                top: 35vh;
            }
        }

        .cover_content {
            .cover_title {
                .h1_middle {
                    bottom: unset; 
                    top: 33vh;
                    h1 { 
                        font-size: 9vw; 
                    }
                }
            }
        }

        .content_body {
            .box {
                h3 { 
                    font-size: 6.5vw;
                    line-height: 8vw; 
                }
            }

            .discover_div {
                bottom: 10.5vw;
                .custom_btn_new {
                    color: #000; 
                }
                .scroll_btn {
                    .scroll_down {
                        svg {
                            width: 4.3vw;
                            height: 4.3vw;
                        }
                    }
                    .scroll_up {
                        svg { 
                            margin-top: -3px;
                        }
                    }
                }
            }
        }
    }
 

    .who_we_are_section {
        .main_wrapper {
            padding-top: 15vh;

            .container-fluid { 
                z-index: 999;
            }

            .bg_shape { 
                top: -31%;

                img {
                    height: 100vh;
                }
            }

            .desc_div { 
                top: 10vh;
                position: relative;

                h5 { 
                    font-size: 3vw; 
                }

                h3 { 
                    font-size: 4.5vw;
                    line-height: 7vw; 
                }

                .tabs_button  {
                    margin-top: 1rem !important;

                    .cta_button { 
                        font-size: 3vw; 
                    }
                }
            }

            .discover_div {
                .scroll_btn {
                    .scroll_down {
                        svg {
                            width: 4.3vw;
                            height: 4.3vw;
                        }
                    }
                }
            }
        }
    }

    .what_we_do_section {
        .main_wrapper {
            padding-top: 12vh; 

            .content_body {
                .desc_div {
                    z-index: 99;
                    h5 { 
                        font-size: 3vw; 
                    }
                    h3 { 
                        font-size: 5vw;
                        line-height: 6.5vw; 
                    }

                    .tabs_button {
                        margin-top: 1rem;
                        margin-bottom: 1rem;

                        .cta_button { 
                            font-size: 3vw; 
                        }
                    }
                } 
                .discover_div {
                    position: relative; 
                    .scroll_btn {
                        .scroll_down {
                            svg {
                                width: 4.3vw;
                                height: 4.3vw;
                            }
                        }
                    }
                }
            }
        }
    } 

    .who_we_are_page {
        .section_cover { 
            background-image: url('../../public/images/whoweare_mobile.png');
            background-size: contain;
            background-position: center top;

            .overlap {
                .cover_content {
                    .cover_title {
                        .h1_top {
                            bottom: unset;
                            top: -32vh;
                            position: relative;
                            h2 { 
                                font-size: 9vw; 
                                line-height: 1.1;
                            }
                            h4 { 
                                font-size: 3vw; 
                                margin-bottom: 7rem;
                            }
                        }
                    }
                }
 
                .discover_div { 
                    bottom: 6vw; 
                    .scroll_down { 
                        font-size: 16px;
                        padding: 10px 6px; 
                    }
                }
            }
        } 

        #meet_the_team_section {
            .sub_title {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 4vw; 
            }
            .description_text{
                h3 { 
                    font-size: 4.7vw;
                    line-height: 6.5vw; 
                } 

                .body_image {
                    img {
                        width: 100% !important;
                    }
                }
            } 
        }
    }

    .what_we_do_page {
        .section_cover {
            background-image: url('../../public/images/whatwedo_mobile.png');
            background-size: contain;
            background-position: center top;

            .overlap {
                .cover_content {
                    .cover_title {
                        .h1_top {
                            bottom: unset;
                            top: -39vh;
                            position: relative;
                            h2 { 
                                font-size: 8vw; 
                                line-height: 1.1;
                            }
                            h4 { 
                                font-size: 3vw; 
                                margin-bottom: 6rem;
                            }
                        }
                    }
                }

                .cover_content {
                    bottom: 20vh;
                    margin-top: unset;
                    .cover_title {
                        .h1_top {
                            h4 { 
                                font-size: 3vw; 
                            }
                            h1 { 
                                font-size: 9.5vw;
                                line-height: 1; 
                            }
                        }
                    }
                }

                .discover_div { 
                    bottom: 5vw; 

                    .scroll_down {  
                        padding: 11px 10px; 
                    }
                }
            }
        }

        #guide_our_vision {
            .main_title { 
                font-size: 3vw; 
            }
            .description_text {
                h3 { 
                    font-size: 4.7vw;
                    line-height: 1.3; 
                }
            }
            .vision_card {
                .card-body {
                    .card-title { 
                        font-size: 4.7vw; 
                    }
                    .card-text { 
                        font-size: 4.7vw;
                        line-height: 1; 
                    }
                }
            }
        }
    } 

    .portfolio_page { 
        .section_cover { 
            // background-image: url('../../public/images/portfolio_mob.png'); 
            // background-size: 100%;
            // background-position: center bottom;
            // height: 36vh;
            // background-color: #cec9c5;

            .cover_one { 
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 20vh; 
                .cover_title {
                    padding-top: 10vh;
                }
            }
            .cover_two {  
                background-size: 100%;
                background-position: 100% bottom;
                width: 100%;
                height: 20vh; 
                background-color: #cec9c5;
            }


            .overlap {
                .bg_shape {  
                    display: none;
                }

                .cover_content {
                    margin-top: unset;
                    bottom: unset;
                    top: 9rem;

                    .cover_title {
                        .h1_top {
                            h4 { 
                                font-size: 3vw;  
                            }
                            h1 { 
                                font-size: 9.7vw;
                                line-height: 1; 
                            }
                        }
                    }
                }
                .discover_div { 
                    bottom: 5vw; 
                    display: none;
                }
            }
        }

        #portfolio_content {
            .page_title {
                h5 { 
                    font-size: 3vw;
                    line-height: 1; 
                    display: none;
                }
            }
            .madison_img {
                margin-top: 1rem;
                margin-bottom: 1rem;

                img {
                    width: 100%;
                }
            }

            .have_question {
                h1 { 
                    font-size: 9.7vw;
                    line-height: 1; 
                }
                p { 
                    font-size: 4.7vw;
                    line-height: 1; 
                }
            }
        }
    }

    .news_page {
        .section_cover {
            height: 140px;
            .overlap {
                .cover_content {
                    margin-top: 6rem;
                    .cover_title {
                        .h1_top {
                            h4 { 
                                font-size: 3vw; 
                            }
                        }
                    }
                }
            }
        } 
 
        .news_page_body{
            .news_article { 
                padding: .5rem 0;

                .article_content {
                    .card-body {
                        .date_time {
                            padding-top: 1rem;
                            .article_sub_title { 
                                font-size: 3vw;
                                line-height: 1.2; 
                            }
                            .article_date {
                                font-size: 3vw;
                            } 
                        }
                        .article_title { 
                            font-size: 5vw;
                            line-height: 1.3;
                        }
                        .card-text { 
                            font-size: 3.5vw;
                            line-height: 1.2;
                        }
                        .read_more {
                            .card_read_more { 
                                font-size: 3vw; 
                            }
                        }
                    }
                }
            }

            .hero_article_title {
                padding: 1rem 1rem 0rem;

                h1 { 
                    font-size: 4vw;
                    line-height: 1; 
                }
            } 
 
        }
    
    }

    .contact_page {
        .section_cover { 
            height: 220px; 
            .overlap {
                .cover_content { 
                    margin-top: 8rem;

                    .cover_title {
                        .h1_top{
                            h4 { 
                                font-size: 3vw; 
                            }
                            h1 { 
                                font-size: 8.5vw; 
                            }
                        }
                    }
                }
            }
        }

        .contact_page_body {
            .any_queries {
                padding: 2rem 1rem;

                h4 { 
                    font-size: 4.7vw;
                    line-height: 1; 
                }
                h2 { 
                    font-size: 4.7vw;
                    line-height: 1; 
                }
            }
        }
    }










    .footer_section {
        .address_ul { 
            font-size: 12px; 
        }

        .footer_menu { 
            justify-content: space-around !important;
            
            ul {
                padding-left: .5rem;

                li {
                    a { 
                        font-size: 12px; 
                    }
                }
            }
        }
    }
}
