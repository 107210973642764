@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');



.header_menu {
    background: transparent;
    z-index: 9999;
    position: absolute;
    width: 100%;
    top: 0;

    .top_nav_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0 10px;
        width: 100%;

        .menu_icon_btn {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 1vw;
            line-height: 1.2;
            letter-spacing: 0.15403px;
            text-transform: uppercase; 
            button {
                background: transparent;
                color: #FFFFFF;
                border: none;
                display: flex;
            }

            svg {
                height: 1.3vw;
            }

            i {
                color: #FFFFFF;
            }
        }

        .logo_col {
            text-align: center;

            img {
                width: auto;
                height: 6.5vh;
            }
        }

        .contact_us_menu {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 1vw;
            line-height: 1;
            letter-spacing: 0.15403px;
            text-transform: uppercase;
            text-align: end;

            a {
                text-decoration: none;
                color: #d4d4d5;

                &:hover {
                    color: #FFFFFF;
                }
            }
        }
    }

    .collapse_menu_list {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1vw;
        line-height: 1;
        letter-spacing: 0.15403px;
        text-transform: uppercase;
        width: 100%;
        margin-top: 1rem;

        .nav-item {
            padding: 0 12px;

            .nav-link {
                color: #d4d4d5;

                &:hover {
                    color: #FFFFFF;
                    text-decoration: underline;
                }
            }


        }
    }
}

.dark_nav {
    .top_nav_bar {
        .menu_icon_btn {

            button,
            i {
                color: #0071CF;
            }
        }

        .contact_us_menu {
            a {
                color: #0071CF;
            }
        }
    }

    .collapse_menu_list {
        .nav-item {
            .nav-link {
                color: #65AAE1;

                &:hover {
                    color: #04345b;
                }
            }
        }
    }

}

.cover_section {
    background-image: url('../../public/images/1920x1080/1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    height: 100vh;
    display: block;
    overflow: hidden;

    .overlap {
        height: 100%;
        width: 100%;
        position: relative;

        .bg_shape {
            position: absolute;
            width: 100%;
            bottom: -13vw;

            img {
                width: 100%; 
            }
        }

        .cover_content {
            z-index: 999;
            width: 100%;
            height: 100%;

            .cover_title {
                position: relative;
                height: 100vh; 
                .h1_middle {
                    text-align: left;
                    bottom: 25%;
                    position: absolute;
                    width: 100%;

                    h1 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 2.8vw; 
                        line-height: 1;
                        letter-spacing: -2px;
                        color: #FFFFFF; 
                    }
                } 

            }
        }


        .discover_div {
            position: absolute;
            bottom: 4%;
            display: grid;
            width: 100%;

            .discover_group {
                text-align: center;
                width: 100%;
            }

            .discover_a { 
                padding: 0.3vw 1.2vw;
                border: 2px solid #FFFFFF;
                border-radius: 25px;
                background: transparent;
                color: #FFFFFF;
                text-decoration: none;
                text-align: center;
                text-transform: uppercase;
                font-size: 1vw;
                margin-bottom: 3vh;
            }

            .scroll_down {
                color: #E4F1E8;
            }
        }


    }
}

.transition_section {
    position: relative;
    height: 100vh;
    overflow: hidden;

    .cover_section { 
        background-image: url('../../public/images/1920x1080/1.png');
        background-size: cover;
        background-position: center top;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;

        .bg_shape {
            position: absolute;
            width: 100%;
            top: 20vh; 

            img {
                width: 100%;
            }
        } 
    }

    .cover_content {
            z-index: 999;
            width: 100%;
            height: 100%;

            .cover_title {
                position: relative;
                height: 100vh; 
                .h1_middle {
                    text-align: left;
                    bottom: 22%;
                    position: absolute;
                    width: 100%;

                    h1 {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 2.8vw; 
                        line-height: 1;
                        letter-spacing: -2px;
                        color: #FFFFFF; 
                    }
                } 

            }
        }

    .content_body {
        // background: #0071CF;
        // position: relative;
        bottom: 0;
        z-index: 999;
        padding-bottom: 7rem;
        height: 20vh;

        .box {
            width: 100%;
            padding: 2vw 0 0;

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.8vw;
                line-height: 2.5vw;
                letter-spacing: -2px;
                color: #FFFFFF;
            }
        }

        .discover_div {
            position: absolute;
            bottom: 1.5vw;
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: 999;

            .scroll_btn {
                text-align: center;
                width: 100%;

                .scroll_down {
                    color: #E4F1E8; 
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;
                    display: inline-block; 
                    width: 40px;
                    height: 40px;
                    line-height: 2.1;

                    svg {
                        width: 1.3vw;
                        height: 1.3vw;
                    }
                }
            } 

            .custom_btn_new {
                color: #FFF;
                border: 2px solid #ddd;
                padding: 8px 20px;
                border-radius: 25px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.intro_section {
    background: #0071CF;
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;
    overflow: hidden;

    .content_body {
        padding-top: 7rem;

        .box {
            width: 75%;
            padding: 30px 0px;

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 60px;
                line-height: 90px;
                // @media only screen and (max-width: 1366px) { 
                //     font-size: 362%;
                //     line-height: 139%;
                // }
                // @media only screen and (min-width: 1367px) {
                //     font-size: 402%; 
                //     line-height: 172%;                   
                // }               
                letter-spacing: -2px;
                color: #FFFFFF;
            }
        }

        .box2 {
            width: 55%;
            padding: 5px 0px;
            float: right;

            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                // @media only screen and (max-width: 1366px) {
                //     font-size: 30px;
                //     line-height: 45px;
                // }
                // @media only screen and (min-width: 1367px) {
                //         font-size: 239%;
                //         line-height: 154%;                
                // }       
                font-size: 30px;
                line-height: 45px;
                letter-spacing: -2px;
                color: #FFFFFF;
            }
        }

        .discover_div {
            position: absolute;

            @media only screen and (max-width: 1366px) {
                bottom: 30px;
            }

            @media only screen and (min-width: 1367px) {
                bottom: 60px;
            }

            display: grid;
            width: 100%;
            z-index: 999;

            .scroll_btn {
                text-align: center;
                width: 100%;
            }

            .scroll_down {
                color: #E4F1E8;
                // font-size: 30px;
                // padding: 12px 18px;
                font-size: 22px;
                padding: 10px 10px;
                border: 2px solid #FFFFFF;
                border-radius: 100px;
                text-decoration: none;
                text-align: center;
            }
        }
    }
}

.who_we_are_section {
    background: #CCE3F5;
    height: 100vh;
    display: block;
    position: relative;
    overflow: hidden;

    .main_wrapper {
        padding-top: 25vh;

        .bg_shape {
            position: absolute;
            width: 100%;
            top: 0;  
            img {
                width: 100%;
                height: 100%; 
            }
        } 

        .container-fluid {
            position: relative;
            z-index: 9999;
        }


        .who_we_img_group {
            position: relative;
            z-index: 9999;

            .group_img {
                text-align: center; 

                img {
                    width: 85%;
                }
            } 
        }

        .desc_div {
            padding: 0 0 0 30px; 
            padding-left: 4.5vw;

            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 1vw;
                line-height: 1;
                text-transform: uppercase;
                color: #0071CF;
            }

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.3vw;
                line-height: 1.6vw;
                letter-spacing: -1px;
                color: #0071CF; 
            }

            .tabs_button {
                .cta_button {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: .7vw;
                    line-height: 1;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    color: #0071CF;
                    margin: 0 5px;
                    border: 2px solid #0071CF;
                    background: transparent;
                    border-radius: 25px;
                    padding: 3px 10px;
                    margin-top: 35px;

                    &:hover {
                        cursor: pointer;
                        background: #0071CF;
                        color: #fff;
                    }
                }

                .btn_active {
                    background: #0071CF;
                    color: #FFFFFF;
                }
            }
        }

        
        .discover_div {
            position: absolute;
            bottom: 1vw;
            display: grid;
            width: 100%;
            z-index: 999;

            .scroll_btn {
                text-align: center;
                width: 100%;

                .scroll_down {
                    color: #E4F1E8; 
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;
                    display: inline-block; 
                    width: 40px;
                    height: 40px;
                    line-height: 2.1;

                    svg {
                        width: 1.3vw;
                        height: 1.3vw;

                        path {
                            stroke: #fff;
                        }
                    }
                }
            } 
        }

    }
}

.what_we_do_section {
    background: #CCE3F5; 
    display: block;
    position: relative;  
    height: auto;
    overflow: hidden; 
    .main_wrapper {
        padding-top: 25vh;
        min-height: 100vh;

        .bg_shape {
            position: absolute;
            width: 100%;
            top: 0;
            right: -60vh;

            img {
                width: 100%;
                height: 100vh;
            }
        }


        .content_body {
            position: relative;  
            height: 75vh; 

            .who_we_img_group {
                position: relative;
                z-index: 999;
    
                .box_img {
                    text-align: center;
        
                    img {
                        width: 75%; 
                    }
                }
            } 
    
            .desc_div { 
                z-index: 9999;
                position: relative;
    
                h5 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1vw;
                    line-height: 1;
                    text-transform: uppercase;
                    color: #0071CF;
                }
    
                h3 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.3vw;
                    line-height: 1.6vw;
                    letter-spacing: -1px;
                    color: #0071CF;
                }
    
                .tabs_button {
                    margin-top: 2rem;
    
                    .cta_button {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: .7vw;
                        line-height: 1;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        color: #0071CF;
                        margin: 0 5px;
                        border: 2px solid #0071CF;
                        background: transparent;
                        border-radius: 25px;
                        padding:3px 10px;
                        margin-top: 35px;
    
                        &:hover {
                            cursor: pointer;
                            background: #0071CF;
                            color: #fff;
                        }
                    }
                }
            }
    
            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;
                z-index: 999;
    
                .scroll_btn {
                    text-align: center;
                    width: 100%;
    
                    .scroll_down {
                        color: #E4F1E8; 
                        border: 2px solid #FFFFFF;
                        border-radius: 100px;
                        text-decoration: none;
                        text-align: center;
                        display: inline-block; 
                        width: 40px;
                        height: 40px;
                        line-height: 2.1;
    
                        svg {
                            width: 1.3vw;
                            height: 1.3vw;
    
                            path {
                                stroke: #fff;
                            }
                        }
                    }
                } 
            }
        } 
    }
}

.latest_news_section {
    background: #FFFFFF;

    .main_wrapper {
        padding-top: 10rem;

        .title_head_div {
            display: flex;

            .title_heading {
                h4 {
                    margin-bottom: 0;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    text-transform: uppercase;
                    color: #0071CF;
                }
            }

            .view_all_link {
                a {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.15403px;
                    text-transform: uppercase;
                    color: #0071CF;
                    text-decoration: none;
                }
            }
        }
    }
}

.who_we_are_page {

    .section_cover {
        // background-image: url('../../public/images/who_we_are_page.png');
        background-image: url('../../public/images/1920x937/2.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        display: block;

        .overlap {
            height: 100%;
            // background: linear-gradient(180deg, #000000 -90%, rgba(0, 0, 0, 0) 100%);
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                bottom: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                bottom: 25%;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1vw;
                            line-height: 1;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin-bottom: 2rem;
                        }

                        h2 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 2.8vw;
                            line-height: 4vw;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                        }
                    }
                }
            }

       
            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 20px;
                    padding: 10px 10px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

    #meet_the_team_section {
        background: #CCE3F5;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 0px;
        gap: 8px;


        .sub_title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1vw;
            line-height: 1;
            text-transform: uppercase;
            color: #0071CF;
        }

        .description_text {
            padding: 0 10px; 

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.3vw;
                line-height: 1.6vw;
                letter-spacing: -1px;
                color: #0071CF;
                margin-bottom: 1.6rem;
            }

            .body_image {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }

}

.what_we_do_page {

    .section_cover { 
        background-image: url('../../public/images/1920x937/3.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        display: block;

        .overlap {
            height: 100%; 
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                bottom: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 28vw;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1vw;
                            line-height: 1;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin-bottom: 2rem;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 2.8vw;
                            line-height: 4vw;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 20%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 2.8vw;
                    line-height: 4vw;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 20px;
                    padding: 10px 10px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

    #guide_our_vision {
        background: #CCE3F5;
        padding: 60px 0px;


        .main_title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 1vw;
            line-height: 24px;
            text-transform: uppercase;
            color: #0071CF;
        }

        .sub_title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 2.8vw;
            line-height: 4vw;
            letter-spacing: -2px;
            color: #0071CF;
            margin-bottom: 40px;
        }


        .vision_card {
            border: none;
            padding: 0 .5rem;
            align-items: center;
            background: transparent;

            img {
                width: 156px;
                height: 146px;
            }

            .card-body {
                .card-title {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.7vw;
                    line-height: 1;
                    white-space: nowrap;
                    text-align: center;
                    text-transform: uppercase;
                    color: #0071CF;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .card-text {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1vw;
                    line-height: 1.5vw;
                    text-align: center;
                    letter-spacing: -1px;
                    color: #0071CF;
                }
            }
        }

        .description_text {
            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.3vw;
                line-height: 1.6vw;
                letter-spacing: -1px;
                color: #0071CF;
            }
        }
    }

}

.portfolio_page {
    .section_cover {
        // background-image: url('../../public/images/1920x1080/ryde_bg.png');
        // background-image: url('../../public/images/1920x937/4.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        // background-position-x: 12rem;
        // width: 100%;
        // height: 100vh;
        // display: block;

        .cover_one {
            background-image: url('../../public/images/new_cover_bg.png'); 
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 30vh;
            display: block;

            .cover_title {
                padding-top: 15vh;
                .top_header {
                    color: #FFF;
                }
            }
        }
        .cover_two {
            background-image: url('../../public/images/rydw_width_img.jpg'); 
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 100% bottom;
            width: 100%;
            height: 70vh;
            display: block;
            background-color: #cec9c5;
        }

        .overlap {
            height: 100%;
            // background: linear-gradient(180deg, #000000 -95%, rgba(0, 0, 0, 0) 100%);
            position: relative;

            .bg_shape {
                position: absolute;
                width: 100%;
                bottom: -15vh;
                z-index: -1;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 10rem;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1vw;
                            line-height: 1;
                            text-transform: uppercase;
                            color: #000000;
                            margin-bottom: 2rem;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 2.8vw;
                            line-height: 4vw;
                            letter-spacing: -2px;
                            color: #000000;

                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 19.5%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 2.8vw;
                    line-height: 4vw;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down { 
                    font-size: 20px;
                    padding: 10px 10px;
                    border: 2px solid #0071ce;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    svg path {
                        stroke: #0071ce
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

    #portfolio_content {
        background: #CCE3F5;
        position: relative;
        overflow: hidden;

        .bg_shape {
            position: absolute;
            width: 100%;
            top: 0%;

            img {
                width: 100%;
            }
        }

        .container-fluid {
            position: relative;
            z-index: 9999;
            padding-top: 5rem;
        }

        .page_title {
            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 1vw;
                line-height: 1;
                text-indent: 3px;
                text-transform: uppercase;
                color: #0071CF;
                margin-bottom: 1rem;
            }

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 2.8vw;
                line-height: 4vw;
                letter-spacing: -2px;
                color: #0071CF;
            }
        }

        .tabs_section {
            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1vw;
                line-height: 24px;
                color: #0071CF;
            }

            .tabs_button {
                button {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1vw;
                    line-height: 24px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #0071CF;
                    margin: 0 5px;
                    border: 1px solid #0071CF;
                    background: transparent;
                    border-radius: 25px;
                    padding: 4px 20px;
                    cursor: auto;
                }

                .btn_active {
                    background: #0071CF;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }

        .madison_img {
            margin-top: 4rem; 

            img {
                width: 30%;
            }


        }

        .have_question {
            margin-bottom: 4rem;

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 2.8vw;
                line-height: 4vw;
                letter-spacing: -2px;
                color: #0071CF;
            }

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.3vw;
                line-height: 1.6vw;
                color: #0071CF;
            }
        }
    }
}

.news_page {
    .section_cover {
        background-image: url('../../public/images/new_cover_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        // height: 550px;
        height: 400px;
        display: block;

        .overlap {
            height: 100%;
            background: linear-gradient(180deg, #000000 -90%, rgba(0, 0, 0, 0) 100%);
            // opacity: 0.8;
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                top: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 10rem;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1vw;
                            line-height: 1;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 2.8vw;
                            line-height: 4vw;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                        }

                        .coming_soon_news {
                            text-align: center;
                            padding: 5rem 0;
                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 20%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 2.8vw;
                    line-height: 4vwx;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 20px;
                    padding: 10px 10px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

    .news_page_body {
        .hr_border {
            border-bottom: 1px solid #0071CF;
        }

        .card_read_more {
            border: none;
            background: transparent;
        }

        .read_less_btm {
            background: transparent;

            button {
                background: #0071cf;
                color: #FFFFFF;
            }
        }

        .hero_article_title {
            padding: 3rem 1rem 1rem;

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 1vw;
                line-height: 1vw;
                letter-spacing: 0.0901704px;
                color: #0071CF;
                margin-bottom: 0;
            }
        }

        .news_article {
            border: none;
            padding: 2rem 0;

            .article_img {
                img {
                    width: 100%;
                }
            }

            .article_content {
                .card-body {
                    padding-top: 0;

                    .date_time {
                        display: flex;

                        .article_sub_title {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1vw;
                            line-height: 1;
                            align-items: center;
                            letter-spacing: 0.0901704px;
                            color: #0071CF;
                        }

                        .article_date {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1vw;
                            line-height: 1;
                            align-items: center;
                            letter-spacing: 0.0901704px;
                            color: #BBBBBB;
                        }
                    }

                    .article_title {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.7vw;
                        line-height: 2.5vw;
                        align-items: center;
                        letter-spacing: 0.148334px;
                        color: #0071CF;
                    }

                    .card-text {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.05vw;
                        line-height: 1.7vw;
                        align-items: center;
                        letter-spacing: 0.148334px;
                        color: #0071CF;
                    }

                    .read_more {
                        .card_read_more {
                            text-decoration: none;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 1vw;
                            line-height: 1;
                            letter-spacing: 0.15403px;
                            text-transform: uppercase;
                            color: #0071CF;
                            display: flex;

                            span {
                                margin: -1px 0 0 5px;

                                i {
                                    font-size: 10px;
                                    border: 1px solid #0071CF;
                                    padding: 2.5px;
                                    border-radius: 100%;
                                }
                            }
                        }
                    }

                    .author_name {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1vw;
                        line-height: 1;
                        align-items: center;
                        letter-spacing: 0.0895591px;
                        color: #0071CF;
                    }
                }

            }
        }
    }

}

.privacy_policy_page {
    .section_cover {
        background-image: url('../../public/images/new_cover_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        // height: 550px;
        height: 400px;
        display: block;

        .overlap {
            height: 100%;
            background: linear-gradient(180deg, #000000 -90%, rgba(0, 0, 0, 0) 100%);
            // opacity: 0.8;
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                top: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 10rem;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 50px;
                            line-height: 75px;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                        }

                        .coming_soon_news {
                            text-align: center;
                            padding: 5rem 0;
                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 20%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 70px;
                    line-height: 100px;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 40px;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 30px;
                    padding: 12px 18px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

}

.terms_of_use_page {
    .section_cover {
        background-image: url('../../public/images/new_cover_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        // height: 550px;
        height: 400px;
        display: block;

        .overlap {
            height: 100%;
            background: linear-gradient(180deg, #000000 -90%, rgba(0, 0, 0, 0) 100%);
            // opacity: 0.8;
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                top: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 10rem;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 50px;
                            line-height: 75px;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                        }

                        .coming_soon_news {
                            text-align: center;
                            padding: 5rem 0;
                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 20%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 70px;
                    line-height: 100px;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 40px;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 30px;
                    padding: 12px 18px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

}

.contact_page {
    .section_cover {
        background-image: url('../../public/images/new_cover_bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 400px;
        display: block;

        .overlap {
            height: 100%;
            background: linear-gradient(180deg, #000000 -90%, rgba(0, 0, 0, 0) 100%);
            // opacity: 0.8;
            position: relative;
            overflow: hidden;

            .bg_shape {
                position: absolute;
                width: 100%;
                top: 0;

                img {
                    width: 100%;
                }
            }

            .cover_content {
                position: absolute;
                z-index: 999;
                width: 100%;
                margin-top: 10rem;


                .cover_title {
                    .h1_top {
                        text-align: left;

                        h4 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1vw;
                            line-height: 1;
                            text-transform: uppercase;
                            color: #FFFFFF;
                        }

                        h1 {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 2.8vw;
                            line-height: 4vw;
                            letter-spacing: -2px;
                            color: #FFFFFF;

                            span {
                                color: #FF00E5;
                            }

                        }
                    }
                }
            }

            .title_bottom {
                position: absolute;
                text-align: center;
                bottom: 20%;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 2.8vw;
                    line-height: 4vw;
                    text-align: center;
                    letter-spacing: -2px;
                    color: #FFFFFF;

                }
            }


            .discover_div {
                position: absolute;
                bottom: 1vw;
                display: grid;
                width: 100%;

                .discover_group {
                    text-align: center;
                    width: 100%;
                }

                .scroll_down {
                    color: #E4F1E8;
                    font-size: 20px;
                    padding: 10px 10px;
                    border: 2px solid #FFFFFF;
                    border-radius: 100px;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }


        }
    }

    .contact_page_body {
        background: #CCE3F5;

        .article_title {
            padding: 4rem 1rem 3rem;

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 2.8vw;
                line-height: 4vw;
                letter-spacing: -2px;
                color: #0071CF;
                margin-bottom: 0;
            }
        }

        .contact_form {
            .form-label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 1vw;
                line-height: 100%;
                align-items: center;
                letter-spacing: -0.02em;
                color: #0071CF;
            }
        }

        .content_box {
            padding: 1rem 1rem 1rem 4rem;

            h3 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.7vw;
                line-height: 2.5vw;
                letter-spacing: -1px;
                color: #0071CF;
            }

            .submit_btn {
                margin-top: 4rem;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 1vw;
                line-height: 1;
                background: #0071CF;
                border-radius: 22px;
                border: none;
                padding: 8px 50px;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }

        .any_queries {
            padding: 8rem 2rem;

            h4 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 1.3vw;
                line-height: 1.6vw;
                text-align: left;
                color: #0071CF;
            }

            h2 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 1.3vw;
                line-height: 1.6vw;
                text-align: left;
                letter-spacing: -2px;
                color: #0071CF;
                white-space: nowrap;
                justify-content: flex-start;

                a {
                    color: #0071CF;
                }
            }
        }
    }

}

.faqs_page {
    background: #CCE3F5;

    .main_section {
        padding-top: 8rem;

        .page_title {
            h5 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-indent: 3px;
                text-transform: uppercase;
                color: #0071CF;
                margin-bottom: 0;
            }

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 50px;
                line-height: 75px;
                letter-spacing: -2px;
                color: #0071CF;
            }
        }

        .tabs_section {
            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #0071CF;
            }

            .tabs_button {
                button {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #0071CF;
                    margin: 0 5px;
                    border: 1px solid #0071CF;
                    background: transparent;
                    border-radius: 25px;
                    padding: 4px 20px;
                }

                .btn_active {
                    background: #0071CF;
                    color: #FFFFFF;
                }
            }
        }

        .page_accordion {
            margin-top: 2rem;

            .accordion {
                .accordion-item {
                    background: transparent;
                    margin-bottom: 1rem;
                    border: 2px solid #0071CF;
                    border-radius: 10px;

                    .accordion-header {
                        .accordion-button {
                            background: transparent;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 28px;
                            line-height: 1;
                            letter-spacing: -2px;
                            color: #0071CF;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            align-items: center;
                            letter-spacing: 0.148334px;
                            color: #0071CF;
                        }
                    }
                }
            }
        }

        .have_question {
            margin-bottom: 4rem;

            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 50px;
                line-height: 75px;
                letter-spacing: -2px;
                color: #0071CF;
            }

            p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #0071CF;
            }

            .contact_btn {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                align-items: center;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                background: #0071CF;
                border-radius: 22px;
                padding: 7px 20px;
                border: none;
                margin-top: 1rem;
                text-decoration: none;
            }
        }
    }
}

.footer_section {
    background: #0071CF;
    padding: 3rem 0;

    .address_ul {
        list-style: none;
        color: #FFFFFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.15403px;

        li {
            span {
                margin-left: 10px;

                a {
                    color: #FFFFFF;
                    text-decoration: none;

                    &:hover {
                        color: #65AAE1;
                    }
                }
            }
        }
    }

    .footer_menu {
        display: flex;

        // float: right;
        ul {
            list-style: none;

            li {
                line-height: 2.5rem;

                a {
                    text-decoration: none;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.15403px;
                    text-transform: uppercase;
                    color: #FFFFFF;

                    &:hover {
                        color: #d4d4d5;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .copy_right_text {
        margin-top: 3rem;
        margin-bottom: 0;
        color: #FFFFFF;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 14.0991px;
        line-height: 20px;
        letter-spacing: 0.15403px;
    }

    .footer_logo {
        margin-top: 2rem;
    }
}

// custome classes
.flex_center_vertically {
    display: flex;
    align-items: center;
}

.flex_center_horizontally {
    display: grid;
    place-items: center;
}