::-webkit-scrollbar {
    width: 0;
}

.dis_show {
    display: none;
}

.dis_hide {
    display: block;
}


.padding_80 {
    padding-left: 80px;
    padding-right: 80px;
}
.menu_up_click {
    margin-top: -1rem;
    transition: .2s; 
}

.scroll_up {
    margin-right: 1rem;
    svg {
        transform: rotate(180deg); 
        margin-top: -8px;
    }
}

.card_one {
    border: none;
    padding: 0 1rem;

    .card-body {
        .card-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 40px;
            align-items: center;
            letter-spacing: 0.163929px;
            text-transform: uppercase;
            color: #0071CF;

            &:hover {
                cursor: pointer;
            }
        }

        .card-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            align-items: center;
            letter-spacing: 0.163929px;
            color: #434343;
        }

        .card_read_more {
            text-decoration: none;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.15403px;
            text-transform: uppercase;
            color: #0071CF;
            display: flex;

            span {
                margin: -1px 0 0 5px;

                i {
                    font-size: 10px;
                    border: 1px solid #0071CF;
                    padding: 2.5px;
                    border-radius: 100%;
                }
            }
        }
    }
}